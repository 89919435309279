export default {
  heading: 'Josefin Sans, sans-serif',
  body: 'Montserrat, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Caveat, cursive',
  googleFonts: [
    'Josefin Sans',
    'Montserrat:100,200,300,400,500,600,700,800,900',
    'Caveat:400,500,600,700',
  ],
  // customFamilies: [''],
  // customUrls: [''],
}
