export default {
  pageHeroV1: {
    container: {
      height: '70vh',
      width: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    },

    content: {
      flexDirection: 'column',
      color: 'white',
      textAlign: 'center',
      h1: {
        fontSize: ['1.75rem', '', '2.5rem'],
        marginBottom: 1,
        letterSpacing: '3px',
        textTransform: 'uppercase',
        color: 'white',
        borderLeft: '7px solid',

        borderColor: 'primary',
        backgroundColor: 'rgba(255,255,255,0.35)',
        padding: '1rem',
      },
      h2: {
        marginBottom: '2rem',
      },
      button: {
        variant: 'buttons.multiLocation',
      },
    },
  },
}
