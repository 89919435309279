export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {},

    '.containerScrolled': {
      backgroundColor: 'white',
      color:'black',
      borderBottom:'2px solid',
      borderColor:'tertiary',
      '.hamburger': {
        div: {
          backgroundColor:'secondary',
        },
      },
    },

    '.logo': {
      img: {
        filter: 'unset',
      },
    },
    ".logoScrolled" : {
      img: {
        maxHeight:'55px',
      },
    },

    '.navItem': {
      padding: '0rem 1rem',
      transition: 'all ease-in-out 0.5s',
      display: 'flex',
      alignItems: 'center',
      border:'none',
      a: {
        fontSize: ['', '', '1.1rem', '1.3rem', '1.4rem'],
        color:'primary',
        fontWeight:'bolder',
      },
      ':hover': {
        padding: '0rem 2rem',
      },
    },

    '.navMenuLogo': {
      maxHeight: '100px',
      position:'static',
      width:['','','','100%'],
      marginBottom:'2rem',
    },
    '.navMenuOpen': {
      width: ['85%', '', '50%', '30%'],
      borderLeft: '1px solid lightgrey',
      backgroundColor: '#fffaef',

      '.navItem': {
        borderLeft: 'solid 10px',
        borderRight: 'solid 10px',
        borderColor: 'tertiary',
        padding: '0rem 1rem',
        transition: 'all ease-in-out 0.5s',
        ':hover': {
          padding: '0rem 2rem',
        },
      },

      '.seperatorLine': {
        margin: '2rem auto',
        width: '90%',
        maxWidth: '300px',
      },
    },

    ".navBlockOpen": {
      width:'100%',
      height:'100vh',
      backgroundColor:'rgba(0,0,0,0.5)',
      position:'absolute',
      left:'0rem',
      top:'0rem',
    },

    ".socialIconsContainer a svg path": {
        fill:'black',
    },
    
  },

  footer: {
    backgroundColor: 'background',
    backgroundImage:'url(https://res.cloudinary.com/gonation/image/upload/v1626367791/sites/delaneys/delaneygraphic.png)',
    backgroundPosition:'bottom center',
    backgroundSize:'contain',
    backgroundRepeat:'no-repeat',
    color: '#202020',
    borderTop: 'lightgrey solid 2px',
    '.logo': {
      marginBottom: '1rem',
    },

    '.aboutText': {
      color: '#202020',
    },

    '.poweredBy  .gonationLogo': {
      filter: 'brightness(0)',
    },

    '.multiButtonContainer': {
      color:'black',
      a: {
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'heading',
        padding: '1rem 1rem',
      },
    },

    '.socialLink': {
      svg: {
        path: {
          fill: '#202020',
        },
      },
    },
  },

  ctaWidget: {},

  loadingPage: {
    backgroundColor: 'white',
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    textTransform: 'uppercase',
    fontSize: ['1.75rem', '2rem', '2.5rem', '3rem'],
    color: "white",
    textShadow: "-3px -3px 0 #fe9900, 3px -3px 0 #fe9900, -3px 3px 0 #fe9900, 3px 3px 0 #fe9900",
    letterSpacing:'3px',
    fontWeight:'bold',
    borderBottom:'4px solid',
    borderColor:'secondary',
  },
  subtitle: {
    textTransform: 'capitalize',
    color: 'primary',
    fontSize: ['1.25rem', '1.5rem', '1.75rem', '2rem'],
    color:'tertiary',
    textTransform:'uppercase',
    fontWeight:'bold',
    fontFamily:'body',
    letterSpacing:'2px',
   
    // borderBottom: 'none',
  },
  text: {
    lineHeight: '1.75',
    color: 'grey',
  },

  sideBySide1: {
    padding:['','','2rem','3rem'],
    '.title': {
      variant: 'customVariants.title',
    },

    '.subtitle': {
      variant: 'customVariants.subtitle',
    },

    '.text': {
      variant: 'customVariants.text',
    },

    '.ctaButton': {
      variant: 'buttons.primary',
    },
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    ".content" : {
      order:['','','2'],
    },
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.hero_content_container': {
      margin: '1rem',
      textAlign:'center',
      justifyContent:'center',
      alignItems:'center',
      margin:'0rem auto',
      left:'50%',
      transform:'translate(-50%,-50%)',
      maxWidth:'unset',
      width:['100%','','80%'],
      padding:'1rem',
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'white',
      fontSize: ['2rem', '2.5rem', '3rem', '4rem'],
      border:'none',
      letterSpacing:'5px',
    },

    '.subtitle': {
      variant: 'customVariants.subtitle',

    },

    '.text': {
      p: {
        color: 'white',
      },
    },
    '.ctaButton': {
      variant: 'buttons.primary',
    },
  },

  homepageHeroShout: {},

  homepageShout: {
    '.title': {
      variant: 'customVariants.title',
    },

    '.subtitle': {
      variant: 'customVariants.subtitle',
    },

    '.text': {
      variant: 'customVariants.text',
    },

    '.shoutCTA': {
      variant: 'buttons.primary',
    },
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
  },
  homepageEvents: {
    variant: 'customVariants.sideBySide2',
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide1',
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide2',
  },




  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    backgroundImage: 'none',
  },

  // ? ========================
  // ? =====  Menu page  ======
  // ? ========================

  menu: {},

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {},

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {},

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    '.address': {
      display: 'none',
    },
  },

  locationMap: {
    '.content_container': {
      backgroundColor: 'primary',
    },
    order: '3',

    h3: {
      textTransform: 'uppercase',
    },
  },

  // ? ========================
  // ? ====  Press Menu  ====
  // ? ========================

  pressMenu: {},

  pressPage: {},

  // ? ========================
  // ? ====  Team Menu  ====
  // ? ========================

  teamMenu: {},

  teamPage: {},

  // ? ========================
  // ? ====  blog Menu  ====
  // ? ========================

  blogMenu: {},

  blogPage: {},
}
