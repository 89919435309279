export default {
  primary: {
    backgroundColor: 'secondary',
    border:'none',
    borderLeft:'5px solid',
    borderRight:'5px solid',
    borderColor:'tertiary',
    borderTop:'5px solid white',
    borderBottom:'5px solid white',
    padding: '1rem 3rem',
    color: 'light',
    fontWeight: '500',
    fontSize: ['1rem', '', '1.1rem'],
    ':hover': {
      backgroundColor: 'primary',

      borderTop:'5px solid',
      borderBottom:'5px solid',
      borderColor:'tertiary',
      borderLeft:'5px solid white',
      borderRight:'5px solid white',


    },
  },
  secondary: {
    backgroundColor: 'transparent',
    color: 'primary',
    border: 'solid 2px',
    borderColor: 'primary',
    ':hover': {
      backgroundColor: 'primary',
      color: 'white',
    },
  },
}
