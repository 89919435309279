export default {
  text: '#333',
  primary: '#fe9900',
  secondary: '#28683f',
  tertiary:'#df262b',
  background: 'white',
  backgroundSecondary: '#fdfdfd',
  light: '#fdfdfd',
  dark: '#020303',
}
